import { getAuthDataCall, postAuthDataCall } from "../utils";

export const getStudentOrders = async () => {
  const resp = await getAuthDataCall(`/auth/studentOrder`);
  return resp;
};

export const getStudentCourse = async () => {
  const resp = await getAuthDataCall(`/auth/studentCourse`);
  return resp;
};

export const getCourseById = async (_id) => {
  const resp = await getAuthDataCall(`/auth/studentCourse/${_id}`);
  return resp;
};

export const lessonCompletionUpdate = async (payload) => {
  const resp = await postAuthDataCall(`/auth/lessonUpdates`, payload);
  return resp;
};
