import React, { useEffect, useState, useContext } from "react";
import StudentAllCourses from "./StudentAllCourses";
import StudentWhislist from "./StudentWhislist";
import InstructorsList from "./InstructorsList";
import { userActions } from "../../containers/user/actions/me";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../Layouts/PageLayout";
import { useLocation } from "react-router-dom";
import { icons } from "../../constants/icons";
import Loader from "../Common/Loader";
import { UserContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function StudentMyLearning() {
  const { t, i18n } = useTranslation();
  let location = useLocation();
  const [showTab, setShowTab] = useState(true);
  const [instrcutor, setInstructor] = useState(false);
  const { loadingUser } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState(location.search);
  const history = useHistory();

  useEffect(() => {
    setActiveTab(location.search);
  }, [location]);

  return (
    <React.Fragment>
      {!loadingUser ? (
        <PageLayout>
          <section className="blue_section">
            <div className="container">
              <h1 className="blue_section_title mtb_50">{t('common.myDashboard')}</h1>
              <div className="blue_section_tabs" dir={ i18n.language == 'ar' ? "rtl" : "ltr"}>
                <a
                  className={activeTab === "?courses" ? "active" : ""}
                  onClick={() => {
                    setShowTab(true);
                    setInstructor(false);
                    history.push({
                      pathname: "/student-learning",
                      search: "courses",
                    });
                  }}
                  style={{ color: "white", cursor: "pointer" }}
                >
                  {t('routes.allCourses')}
                </a>
                <a
                  className={activeTab === "?wishlist" ? "active" : ""}
                  onClick={() => {
                    history.push({
                      pathname: "/student-learning",
                      search: "wishlist",
                    });
                  }}
                  style={{ color: "white", cursor: "pointer" }}
                >
                   {t('common.wishlist')}
                </a>
                <a
                  className={activeTab === "?instructors" ? "active" : ""}
                  onClick={() => {
                    history.push({
                      pathname: "/student-learning",
                      search: "instructors",
                    });
                  }}
                  style={{ color: "white", cursor: "pointer" }}
                >
                  {t('common.myInstructors')}
                </a>
              </div>
            </div>
          </section>
          {activeTab === "?courses" && <StudentAllCourses />}
          {activeTab === "?wishlist" && <StudentWhislist />}
          {activeTab === "?instructors" && <InstructorsList />}
        </PageLayout>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}