import React, { useContext, useState, useEffect } from "react";
import PageLayout from "../Layouts/PageLayout";
import { UserContext } from "../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import UserCartData from "./UserCartData";
import { getCheckout, placeCheckout, promocode } from "../services/checkout";
import { getAddressesList } from "../services/address";
import Loader from "../Common/Loader";
import {scrollToSelector, showError, showSuccess} from "../Utils/helper";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
/**
 *
 * @param {Checkout} author by Bilal
 * @returns
 */

const customStyles = {
  padding: "5px 0px",
  borderRadius: "11px",
  borderCcolor: "red",
};
function Checkout() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [courseCart, setCourseCart] = useState(null);
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  // const { cartCount, setCartCount, cartData } = useContext(CartContext);
  const { user, loggedIn, loadingUser } = useContext(UserContext);
  const [total, setTotal] = useState(null);
  const [promocodeTotal, setPromocodeTotal] = useState(null);
  const [finalTotal, setFinalTotal] = useState(null);

  const [promo, setPromo] = useState("");
  const [promoList, setPromoList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [displayAddress, setDisplayAddress] = useState("");
  const [addressName, setAddressName] = useState({
    value: "",
    label: ""
  });
  let _cartCourseLoggedOut = JSON.parse(localStorage.getItem("cartCourse"));
  const [addresses, setAddresses] = useState([]);
  const [details, setDetails] = useState({
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
    mobile_number: user?.mobile_number,
    country_id: 112,
    address_line1: "",
    address2: "",
    address3: "",
    address3: "",
    //kuwait
    block: "",
    street: "",
    house: "",
    apartment: "",
    driections: "",
    area: "",
  });

  const [countryId, setCountryId] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (loggedIn) {
      getAddresses()
      getCheckoutDetails();
    }
  }, []);

  function changeHandler(e) {
    const { name, value } = e.target;

    setDetails({
      ...details,
      [name]: value,
    });
  }
  async function getCheckoutDetails() {
    const resp = await getCheckout();
    if (resp.success) {
      setTotal(resp.data.total);
      setPromocodeTotal(0);
      setFinalTotal(resp.data.total);
      delete resp.data.total;
      let arrayOfCourse = [];
      Object.values(resp.data).map((_item) => {
        arrayOfCourse.push(_item);
      });
      let _arr = [];
      arrayOfCourse.map((_item) => {
        _arr.push(_item.course_id);
        setCountryId(_item.courses.country_id)
      });
      setCourseList(_arr);
      setCourseCart(arrayOfCourse);
    } else {
      showError(t('alertMessage.wrong'));
    }
    setLoading(false);
  }

  async function getAddresses() {
    setLoading(true);
    const resp = await getAddressesList();
    if (resp.success) {
      let obj = [];
      resp.data.forEach((item) =>
        obj.push({ 
          value: item.id, 
          label: item.category, 
          address: item.country_id === 112 ? `${item?.apartment}, ${item?.house}, ${item?.street}, ${item?.city} - ${item?.country?.name}` : `${item?.address}, ${item?.city}, ${item?.state}, ${item?.pin_code} - ${item?.country?.name}`
        })
      );
      setAddresses(obj);
    } else {
      showError(t('alertMessage.wrong'));
    }
    setLoading(false);
  }



  useEffect(() => {
    let _price = 0;
    if (!loggedIn) {
      _cartCourseLoggedOut?.map((item) => {
        if (item?.is_free === 0) {
          _price += item?.course_sale.on_sale
            ? item?.course_sale?.new_price
            : item?.course_sale?.old_price;
        }
      });
      setPromocodeTotal(_price);
      setTotal(_price);
      setFinalTotal(_price);
    }
  }, [_cartCourseLoggedOut]);
  useEffect(() => {
    setDetails({
      ...details,
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      mobile_number: user?.mobile_number,
    });
  }, [user]);

  useEffect(() => {
    localStorage.removeItem("update_cart")
  }, [])

  async function placeholder(e) {
    e.preventDefault();
    setSpinner(true);
    if (!loggedIn) {
      showError(t('alertMessage.signUpToPurchase'));
      return;
    }
    Object.entries(details).map((_item) => {
      if (_item[1] === "") {
        delete details[_item[0]];
      }
    });

    details["applied_promocode"] = promoList;
    details["cart_course"] = courseList;
    details["total"] = finalTotal;
    details["promocode_total"] = promocodeTotal;
    details["final_total"] = finalTotal;
    details["address_id"] = addressName.value;
    details["country_id"] = countryId;

    const resp = await placeCheckout(details);
    if (!resp.success) {
      if (resp?.message?.address_id?.[0]) {
        showError(t('alertMessage.validAddress'), 3000, "top-center");
        scrollToSelector('address');
        setSpinner(false);
        return false;
      }
    }

    if (resp?.message === 'success' || resp?.success === true) {
      window.open(resp.transaction.url, "_self");
    } else {
      showError("Error");
    }
    setSpinner(false);
  }

  async function applyPromoCode(e) {
    e.preventDefault();
    if (!promo) {
      showError(t('alertMessage.enterPromo'));
      return;
    }
    if (!loggedIn) {
      showError(t('alertMessage.signUpToPurchase'));
      return;
    }
    let payload = {
      cart_course: courseList,
      code: [promo, ...promoList],
    };
    const resp = await promocode(payload);
    if (resp.success) {
      if (resp.data === finalTotal) {
        showError(t('alertMessage.promoCodeDoesNotExist'));
        return;
      }
      setFinalTotal(resp.data);
      setPromocodeTotal(total - resp.data);
      showSuccess(t('alertMessage.success'));
      setPromoList([...promoList, promo]);
      setPromo("");
    } else {
      showError(t('alertMessage.invalidPromoCode'));
    }
  }

  function setRedirectRoute()
  {
    localStorage.setItem("previous_route", window.location.pathname);
  }
  return (
    <>
      {loading && loadingUser && courseCart ? (
        <Loader />
      ) : (
        <PageLayout>
          <section className="mt_50">
            <form onSubmit={placeholder}>
              <div className="container">
                <h1 className="blue_title">{t('common.checkout')}</h1>
                <div className="row" dir={ i18n.language == 'ar' ? "rtl" : "ltr"}>
                  <div className="col-12 col-lg-4">
                    <div className="cart_wrapper">
                      <h2>{t('checkout.billing_details')}</h2>
                      <div className="form-group">
                         <label for="">{t('common.firstName')}</label>
                        <input
                          type="text"
                          name=""
                          id=""
                          className="form-control"
                          value={user?.first_name}
                          disabled
                        />
                      </div>
                      <div className="form-group">
                         <label for="">{t('common.lastName')}</label>
                        <input
                          type="text"
                          name=""
                          id=""
                          className="form-control"
                          value={user?.last_name}
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label for="">{t('common.email')}</label>
                        <input
                          type="email"
                          name=""
                          id=""
                          className="form-control"
                          value={user?.email}
                          disabled
                        />
                      </div>

                      <div className="form-group">
                        <label className="required-field">{t('common.mobileNumber')}</label>
                        <input
                          type="number"
                          name="mobile_number"
                          className="form-control"
                          value={details?.mobile_number}
                          onChange={changeHandler}
                          required
                          placeholder={t('common.mobileNumber')}
                        />
                      </div>
                      { addresses.length > 0 ? (
                        <div className="form-group">
                          <label className="required-field" id="address">{t('common.selectAddress')}</label>
                          <Select
                            name="form-field-name"
                            className="react_select"
                            value={addressName}
                            onChange={(selected) => {
                              setAddressName(selected);
                              setDisplayAddress(selected.address);
                            }}
                            options={addresses}
                            styles={customStyles}
                            required
                          />
                          {
                            displayAddress !== "" ? (
                              <p className="note mt-3">{t('common.address')} <span>{displayAddress}</span></p>
                            ): ""
                          }
                          <br/>
                          <Link
                              className="btn_primary btn-block py-3"
                              to={{
                                pathname: "/addresses",
                                search: "add",
                              }}
                              onClick={setRedirectRoute}
                          >
                            {t('checkout.add_address')}
                          </Link>
                        </div>
                      ) : (
                        <Link
                            className="btn_primary btn-block py-3"
                            to={{
                              pathname: "/addresses",
                              search: "add",
                            }}
                            onClick={setRedirectRoute}
                          >
                            {t('checkout.add_address')}
                          </Link>
                       )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="cart_wrapper mb_30">
                      <h2>{t('checkout.payment_method')}</h2>
                      <div className="form-group">
                        <div className="pay_method">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked="checked"
                            required
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1"
                          >
                            {t('checkout.knet')}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="cart_wrapper">
                      <h2>{t('checkout.apply_promo_code')}</h2>
                      <div className="form-group">
                        <label for="">{t('checkout.promo_code')}</label>
                        <div className="form_input">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('checkout.enter_promo_code')}
                            value={promo}
                            onChange={(e) => setPromo(e.target.value)}
                          />
                          <a href="" className="link" onClick={applyPromoCode}>
                            {t('checkout.apply')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="cart_wrapper">
                      <h2>{t('checkout.review_order')}</h2>
                      {(loggedIn && courseCart?.length === 0) ||
                      (!loggedIn && _cartCourseLoggedOut?.length === 0) ? (
                        <span>{t('checkout.no_course_found')}</span>
                      ) : (
                        <div className="ro_wrapper">
                          <div className="ro_header">
                            <div className="ro_bold">{t('common.product')}</div>
                            <div className="ro_bold">{t('common.total')}</div>
                          </div>
                          <UserCartData
                            cartArr={
                              loggedIn ? courseCart : _cartCourseLoggedOut
                            }
                          />
                          <div className="ro_subtotal">
                            <div className="ro_subrow">
                              <div className="ro_bold">{t('common.sub_total')}</div>
                              {(loggedIn ? (courseCart !== null && courseCart.length > 0 && courseCart[0]?.courses.country_id === 112 ) : (_cartCourseLoggedOut.length > 0 && _cartCourseLoggedOut[0]?.courses.country_id === 112) )? // is kuwait?
                                <div className="ro_price">{t('course.price_in_kd', { price:  total})}</div>
                                :
                                <div className="ro_price">{t('course.price_in_bd', { price:  total})}</div>
                              }
                            </div>
                            <div className="ro_subrow">
                              <div className="ro_bold">{t('checkout.after_applying_code')}</div>
                              <div className="ro_price">
                                {(loggedIn ? (courseCart !== null && courseCart?.length > 0 && courseCart[0]?.courses.country_id === 112) : (_cartCourseLoggedOut.length > 0 && _cartCourseLoggedOut[0]?.courses.country_id === 112) ) ? // is kuwait?
                                  t('course.price_in_kd', { price: promocodeTotal})
                                  :
                                  t('course.price_in_bd', { price: promocodeTotal})
                                }
                              </div>
                            </div>
                          </div>
                          <div className="ro_total">
                            <div className="ro_bold">{t('common.total')}</div>
                            {(loggedIn ? (courseCart !== null && courseCart.length > 0 && courseCart[0]?.courses.country_id === 112) : (_cartCourseLoggedOut.length > 0 && _cartCourseLoggedOut[0]?.courses.country_id === 112) )  ? // is kuwait?
                               <div className="ro_price">{t('course.price_in_kd', { price: finalTotal})}</div>
                              :
                              <div className="ro_price">{t('course.price_in_bd', { price: finalTotal})}</div>
                            }
                          </div>

                          { addresses.length > 0 ? (
                            <>
                              <div className="ro_check terms_condition">
                                <input
                                  className="form-check-input cursor_pointer"
                                  type="checkbox"
                                  id=""
                                  required
                                />
                                <label className="form-check-label">
                                  <Trans i18nKey="common.termsAndCondition">
                                    I have read and accept the
                                    <a className="link cursor_pointer" href="/terms-condition" target="_blank"> terms and conditions.</a>
                                  </Trans>
                                </label>
                              </div>
                              <button
                                className="btn_primary big_btn full_btn"
                                type="submit"
                              >
                                {spinner && (
                                  <span className="mr-2 spinner-border spinner-border-sm" />
                                )}
                                {t('common.placeOrder')}
                              </button>
                            </>
                          ) : null }
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <ToastContainer />
        </PageLayout>
      )}
    </>
  );
}

export default Checkout;
