import React, { useState, useContext } from "react";
import { icons } from "../../constants/icons";
import { GiDuration } from "react-icons/gi";
import {
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import { showError, utcToLocalDateTime } from "../Utils/helper";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import { addCourseToCart } from "../Utils/helper";
import { Spinner } from "../Utils/helper";
import { addToCartBulk } from "../services/courses";
import { UserContext } from "../context/AuthContext";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import EmptyCartModal from "../modals/EmptyCartModal"
import format from "../../services/i18n-format"
import { useSelector } from "react-redux";

function DetailCard({ data, helperProps, openLoginModal }) {
  const { t, i18n } = useTranslation();
  const { user, loggedIn } = useContext(UserContext);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [preview, setPreview] = React.useState(null);
  const [loadingCart, setLoadingCart] = useState(false);
  const userCountry = useSelector((state) => state.unauthedUserInfo.country);
  const [show, setShow] = useState(false);

  const [openEmptyCartModal, closeEmptyCartModal] = useModal(() => {
    setShow(true);
    return (
      <ReactModal
        isOpen
        ariaHideApp={false}
        className="react_modal"
        overlayClassName="modal_overlay"
      >
        <EmptyCartModal
          closeModal={closeEmptyCartModal}
          setShow={setShow}
          loggedIn={loggedIn}
        />
      </ReactModal>
    );
  });


  const history = useHistory();

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const ChapterPrice = () => {
    let res = data?.data?.chapter
      ?.map((bill) => bill.price)
      ?.reduce((acc, bill) => bill + acc);

    if(res)
    {
      return (
        <div>
          <h6>{data.data.country_id === 112 ? t('course.total_course_price',{ price: res}) : t('course.total_course_price_in_bd',{ price: res})}</h6>
        </div>
      );
    }
    return false;
  };

  async function handleBuyNow() {
    if (!helperProps.loggedIn) {
      // showError("Kindly, login to buy course!!");
      openLoginModal();
      return;
    }
    let payload = { courses: [data?.data?.id] };
    const resp = await addToCartBulk(payload, Cookies.get("EAPISID"));
    if (resp.data.success) {
      // console.log("added");
      history.push("/checkout");
    } else {
      showError(t('alertMessage.wrong'));
    }
  }
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        // style={customStyles}
        contentLabel="Example Modal"
        className="react_modal"
        overlayClassName="modal_overlay"
      >
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <img src={icons.icon_close} alt="" />
        </button>
        <div className="modal-body embed-responsive-16by9">
          <video
            className="embed-responsive-item"
            width="100%"
            src={process.env.REACT_APP_ASSETS + data?.data?.course_preview?.url}
            autoPlay
            class="p-4"
            controls
          />
        </div>
      </Modal>
      <div className="course_detail_box p_30">
        <div className="course_video_thumb mb_20">
          <img
            src={
              data?.data?.course_image
                ? process.env.REACT_APP_ASSETS + data?.data?.course_image
                : icons.course_thumbnail
            }
            alt="5564"
          />
          {data?.data?.course_preview?.url && (
            <div className="play_button" style={{ cursor: "pointer" }}>
              <img
                src={icons.icon_circle_play}
                alt=""
                onClick={() => {
                  //   setPreview(data?.data?.course_preview?.url);
                  openModal();
                }}
              />
            </div>
          )}
        </div>
        {/* <span>Course Price</span> */}
        <div className="cp_wrapper mb_30">
          {data?.data?.is_free === 0 ? (
            <>
              {data?.data?.course_sale?.on_sale ? (
                data?.data?.country_id === 112 ?
                  <>
                    <div className="price_discount">{t('course.price_in_kd', { price: data?.data?.course_sale?.new_price})}</div>
                    <div className="price_strike">{t('course.price_in_kd', { price: data?.data?.course_sale?.old_price})}</div>
                    <div className="price_percentage">{`${data?.data?.course_sale?.percent_off} %`}</div>
                  </>
                  :
                  <>
                    <div className="price_discount">{t('course.price_in_bd', { price: data?.data?.course_sale?.new_price})}</div>
                    <div className="price_strike">{t('course.price_in_bd', { price: data?.data?.course_sale?.old_price})}</div>
                    <div className="price_percentage">{`${data?.data?.course_sale?.percent_off} %`}</div>
                  </>
              ) : (
                data?.data?.country_id === 112 ?
                  <div className="price_discount">{t('course.price_in_kd', { price: data?.data?.course_sale?.old_price})}</div>
                  :
                  <div className="price_discount">{t('course.price_in_bd', { price: data?.data?.course_sale?.old_price})}</div>
              )}
            </>
          ) : (
            <div className="price_discount">{t('common.free')}</div>
          )}
        </div>
        {<ChapterPrice />}

        {helperProps?.loggedIn ? (
          <>
            { data?.data?.user_purchased_course === false ? (
              <>
                {user?.type === "instructor" ? null : loadingCart !== "cart-loading" ? (
                  <button
                    className="btn_secondary full_btn big_btn mb_20"
                    onClick={() =>
                      addCourseToCart(
                        { course_id: data?.data?.id },
                        helperProps,
                        "cart-loading",
                        data?.data,
                        {
                            addToCartSuccess: t('alertMessage.addToCartMsg'),
                            courseExist: t('alertMessage.courseExist')
                        },
                        openEmptyCartModal,
                      )
                    }
                  >
                    <span className="btn_text">{t('common.addToCart')}</span>
                    <div className="btn_icon">
                      <img src={icons.icon_cart} alt="" />
                    </div>
                  </button>
                ) : (
                  <Spinner />
                )}
                { user?.type === "instructor" ? null : (
                <button
                  className="btn_primary full_btn big_btn mb_20"
                  onClick={() => handleBuyNow()}
                >
                  {t('common.buyNow')}
                </button>)}
              </>
            ) : (
              <Link
                onClick={() => {
                  history.push(`/course/${data?.data?.id}`);
                }}
                className="btn_primary full_btn big_btn mb_20"
              >
                {t('common.continueLearning')}
              </Link>
            )}
          </>
        ) : (
          <>
            {loadingCart !== "cart-loading" ? (
              <button
                className="btn_secondary full_btn big_btn mb_20"
                onClick={() =>
                  addCourseToCart(
                    { course_id: data?.data?.id },
                    helperProps,
                    "cart-loading",
                    data?.data,
                    openEmptyCartModal,
                  )
                }
              >
                <span className="btn_text">{t('common.addToCart')}</span>
                <div className="btn_icon">
                  <img src={icons.icon_cart} alt="" className="btn-icon-add-to-cart"/>
                </div>
              </button>
            ) : (
              <Spinner />
            )}
            <button
              className="btn_primary full_btn big_btn mb_20"
              onClick={() => handleBuyNow()}
            >
              {t('common.buyNow')}
            </button>
          </>
        )}

        <h6 className="mb_10">{t('common.courseInclude')}:</h6>
        <ul className="course_stat pb_10 border_bottom">
          {!data?.data?.course_validities?.is_lifetime ? (
            <li>
              <figure>
                {/* <GiDuration /> */}
                <img src={icons.icon_access} alt="" />
              </figure>
              <span>{t('course.access_for_days', {days: data?.data?.course_validities?.duration})}</span>
            </li>
          ) : (
            <li>
              <figure>
                <img src={icons.icon_access} alt="" />
              </figure>
              <span>{t('common.unlimitedAccess')}</span>
            </li>
          )}
          <li>
            <figure>
              <img src={icons.icon_mobile} alt="" />
            </figure>
            <span>{t('common.accessdevice')}</span>
          </li>
          { data?.data?.video_duration ? (
              <li>
                <figure>
                  <img src={icons.icon_video} alt="" />
                </figure>
                <span>{t('course.course_duration', {hours: data?.data?.video_duration?.hours, minutes: data?.data?.video_duration?.minutes})}</span>
              </li>
          ) : ("") }
          { data?.data?.audio_duration ? (
              <li>
                <figure>
                  <img src={icons.icon_audio_new} alt=""/>
                </figure>
                <span>{t('course.course_duration', {hours: data?.data?.audio_duration?.hours, minutes: data?.data?.audio_duration?.minutes})}</span>
              </li>
          ) : ("") }
          { data?.data?.pdf_count ? (
              <li>
                <figure>
                  <img src={icons.icon_pdf} alt="" />
                </figure>
                <span>{ t('common.documents',{documents: data?.data?.pdf_count}) }</span>
              </li>
          ) : ("") }
          { data?.data?.exercises ? (
              <li>
                <figure>
                  <img src={icons.icon_exercises} alt="" />
                </figure>
                <span>{ t('course.exercises',{ exercise: data?.data?.exercises}) }</span>
              </li>
          ) : ("") }
        </ul>
        {
          data?.data?.show_social_media === true ?
          <>
          {data?.data?.show_social_media}
          <h6 className="mb_10">{t('common.shareCourse')}</h6>
          <div className="social_media">
            {data?.data?.socials?.map((item) => (
              <>
                {item === "Twitter" ? (
                  <TwitterShareButton 
                    title={
                      'Thought you might enjoy this course on @Dawraty:'+data?.data?.name
                    }
                    url={window.location.href}
                  >
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                ) : null}
                {item === "Whatsapp" ? (
                  
                  <WhatsappShareButton
                    title={
                      'Thought you might enjoy this course on @Dawraty: '+data?.data?.name
                    }
                    url={window.location.href}
                  >
                    <img src={icons.icon_whatsapp} alt="" className="share-icons"/>
                    {/* <WhatsappIcon size={32} round={true} /> */}
                  </WhatsappShareButton>
                ) : null}
                {item === "Email" ? (
                  <EmailShareButton
                    title={
                      'Thought you might enjoy this course on @Dawraty: '+data?.data?.name
                    }
                    url={window.location.href}
                  >
                    {/* <EmailIcon size={32} round={true} /> */}
                    <img src={icons.icon_mail} alt="" className="share-icons" />
                  </EmailShareButton>
                ) : null}
              </>
            ))}
          </div>
          </> : null
        }
      </div>
    </>
  );
}

export default DetailCard;
