import React, {useContext, useState} from "react";
import {icons} from "../../constants/icons";
import {useHistory, Link} from "react-router-dom";
import {CartContext} from "../context/CartContext";
import {UserContext} from "../context/AuthContext";
import {addToWishListData, addCourseToCart, Spinner} from "../Utils/helper";
import {ToastContainer} from "react-toastify";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import EmptyCartModal from "../modals/EmptyCartModal"
import getTranslationFromMany from "../../services/helper";

function Card(props) {
    const { t, i18n } = useTranslation();
    const {cartCount, setCartCount, setCartData, cartData} = useContext(CartContext);
    const {loggedIn} = useContext(UserContext);
    const {id, title, tutor, price, thumbnail, isFree, data, isPurchased, translation = {}, instructorsList} =
        props;
    const history = useHistory();
    const [loadingCart, setLoadingCart] = useState(false);
    const [show, setShow] = useState(false);

    let helperProps = {
        cartCount,
        setCartCount,
        setCartData,
        loggedIn,
        setLoadingCart,
        cartData,
        t
    };
    const countryId = data.country_id;

    const [openEmptyCartModal, closeEmptyCartModal] = useModal(() => {
        setShow(true);
        return (
          <ReactModal
            isOpen
            ariaHideApp={false}
            className="react_modal"
            overlayClassName="modal_overlay"
          >
            <EmptyCartModal
              closeModal={closeEmptyCartModal}
              setShow={setShow}
              loggedIn={loggedIn}
            />
          </ReactModal>
        );
      });

    function redirect(id) {
        history.push(`/course-details/${id}`);
    }

    return (
        <>
            <div className="col-12 col-md-3">
                <div className="course_box mb_50">
                    <div onClick={() => redirect(id)}>
                    <div className="course_thumbnail" >
                        <img
                            src={
                                thumbnail
                                    ? process.env.REACT_APP_ASSETS + thumbnail
                                    : icons.course_thumbnail
                            }
                            alt=""
                        />
                    </div>
                    {/* <h2 className="course_box_title" title={title}>{title.length > maxLength ? title.substring(0, maxLength) + "..." : title}</h2> */}
                    <h2 className="course_box_title" title={title}>{t('dynamic', { en: title, ar: getTranslationFromMany(translation, 'name', title) })}</h2>
                    {/* <div
                        className="course_box_subtitle"
                        style={{textTransform: "capitalize"}}
                    >
                        { tutor ? t('courseAuthors.'+tutor, tutor) : ""}
                    </div> */}
                    <div className="cp_wrapper">
                        {isFree === 1 ? (
                            <div className="price_discount">{t('common.free')}</div>
                        ) : !price?.on_sale ? (
                            countryId === 112 ?
                                <div className="price_discount">{t('course.price_in_kd',{ price: price?.old_price})}</div>
                                :
                                <div className="price_discount">{t('course.price_in_bd',{ price: price?.old_price})}</div>
                        ) : (
                            countryId === 112 ?
                                <>
                                    <div className="price_discount">{t('course.price_in_kd',{ price: price?.new_price})}</div>
                                    <div className="price_strike">{t('course.price_in_kd',{ price: price?.old_price})}</div>
                                </>
                                :
                                <>
                                    <div className="price_discount">{t('course.price_in_bd',{ price: price?.new_price})}</div>
                                    <div className="price_strike">{t('course.price_in_bd',{ price: price?.old_price})}</div>
                                </>
                        )}
                    </div>
                    </div>
                    {isPurchased === null || !isPurchased?.is_purchased ? (
                        <div className="course_btns" dir={ i18n.language == 'ar' ? "rtl" : "ltr"}>
                            {loadingCart !== "cart-loading" ? (
                                <button
                                    className="btn_primary big_btn"
                                    onClick={() =>
                                        addCourseToCart(
                                            {course_id: id},
                                            helperProps,
                                            "cart-loading",
                                            data,
                                            {
                                                addToCartSuccess: t('alertMessage.addToCartMsg'),
                                                courseExist: t('alertMessage.courseExist')
                                            },
                                            openEmptyCartModal,
                                        )
                                    }
                                >
                                    <div className="btn_icon">
                                        <img src={icons.icon_cart_white} alt=""/>
                                    </div>
                                </button>
                            ) : (
                                <Spinner/>
                            )}
                            {loadingCart !== "wishlist-loading" ? (
                                <button
                                    className="btn_secondary big_btn"
                                    onClick={() =>
                                        addToWishListData({course_id: id}, helperProps)
                                    }
                                >
                                    <div className="btn_icon">
                                        <img src={icons.icon_heart} alt=""/>
                                    </div>
                                </button>
                            ) : (
                                <Spinner/>
                            )}
                        </div>
                    ) : (
                        <>
                            <Link
                                className="btn_primary btn-block py-3"
                                // onClick={() => {
                                //   history.push(`/course/${id}`);
                                // }}
                                to={{
                                    pathname: "/course",
                                    state: id,
                                }}
                            >
                                {t('common.continueLearning')}
                            </Link>
                        </>
                    )}
                </div>
            </div>
            <ToastContainer/>
        </>
    );
}

export default Card;
