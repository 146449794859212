import React, { useState, useEffect, useContext } from "react";
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import Login from "../modals/Login";
import Signup from "../modals/Signup";
import ForgotPassword from "../modals/ForgotPassword";
import { icons } from "../../constants/icons";
import InstructorSignup from "../modals/InstructorSignup";
import { Link } from "react-router-dom";
import { getDataCall } from "../services/utils";
import { UserContext } from "../context/AuthContext";
import { CartContext } from "../context/CartContext";
import {
  printcartData,
  handleSearchChange,
  logoutUser,
  printCartDataLoggedOut,
} from "./navbar/helper";
import { useHistory } from "react-router-dom";
import { RiDashboardLine } from "react-icons/ri";
import { IoBookOutline } from "react-icons/io5";
import { AiOutlineShopping } from "react-icons/ai";
import { ar_messages, messages } from '../../constants/message';
import MenuItem from '../../components/menuItem';
import LanguageSelector from "./LanguageSelector";
import { t, useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { Spinner } from "../Utils/helper";

function Navbar({ me }) {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [showMMCategory, setShowMMCategory] = useState(false);
  const [searchBox, setSearchBox] = useState(false);
  const history = useHistory();
  const [searchCourseData, setSearchCourseData] = useState([]);
  const [checkoutPrice, setCheckoutPrice] = useState("");
  const { user, loggedIn, category, notificationCount, notifications, resetNotification } = useContext(UserContext);
  const { cartCount, setCartCount, cartData, setCartData} = useContext(CartContext);
  let _cartData = JSON.parse(localStorage.getItem("cartCourse"));
  let cartLocalStorage = localStorage.getItem("update_cart")
  const [searchParam, setSearchParam] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const countryInfo = useSelector(state => state.unauthedUserInfo.country)

  let helperProps = {
    cartCount,
    setCartCount,
    setCartData,
    loggedIn,
    setLoadingCart,
    t
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [show]);

  useEffect(() => {
    if (searchCourseData === null || searchCourseData.length <= 0) {
      setSearchBox(false);
    } else {
      setSearchBox(true);
    }
    if (searchParam.length > 0) {
      setSearchBox(true);
    }
  }, [searchCourseData, searchParam]);

  useEffect(() => {
    if (loggedIn && cartLocalStorage) {
        history.push('/checkout')
    }
  }, [loggedIn, cartLocalStorage])

  function redirect(id) {
    history.push(`/course-details/${id}`);
    setSearchBox(false);
  }

  const [openLoginModal, closeLoginModal] = useModal(() => {
    setShow(true);
    return (
      <ReactModal
        isOpen
        ariaHideApp={false}
        className="react_modal"
        overlayClassName="modal_overlay"
      >
        <button
          type="button"
          className="close"
          onClick={() => {
            closeLoginModal();
            setShow(false);
          }}
        >
          <img src={icons.icon_close} alt="close" />
        </button>
        <Login
          forgotOpen={openForgotPasswordModal}
          loginClose={closeLoginModal}
          signupOpen={openSignupModal}
        />
      </ReactModal>
    );
  });
  const [openSignupModal, closeSignupModal] = useModal(() => {
    setShow(true);
    return (
      <ReactModal
        isOpen
        ariaHideApp={false}
        className="react_modal"
        overlayClassName="modal_overlay"
      >
        <button
          type="button"
          className="close"
          onClick={() => {
            closeSignupModal();
            setShow(false);
          }}
        >
          <img src={icons.icon_close} alt="close" />
        </button>
        <Signup closeSignup={closeSignupModal} openLogin={openLoginModal} />
      </ReactModal>
    );
  });
  const [openInstructorSignupModal, closeInstructorSignupModal] = useModal(
    () => {
      setShow(true);
      return (
        <ReactModal
          isOpen
          ariaHideApp={false}
          className="react_modal"
          overlayClassName="modal_overlay"
        >
          <button
            type="button"
            className="close"
            onClick={() => {
              closeInstructorSignupModal();
              setShow(false);
            }}
          >
            <img src={icons.icon_close} alt="close" />
          </button>
          <InstructorSignup
            closeSignup={closeInstructorSignupModal}
            openLogin={openLoginModal}
          />
        </ReactModal>
      );
    }
  );
  const [openForgotPasswordModal, closeForgotPasswordModal] = useModal(() => {
    setShow(true);
    return (
      <ReactModal
        isOpen
        ariaHideApp={false}
        className="react_modal"
        overlayClassName="modal_overlay"
      >
        <button
          type="button"
          className="close"
          onClick={() => {
            closeForgotPasswordModal();
            setShow(false);
          }}
        >
          <img src={icons.icon_close} alt="close" />
        </button>
        <ForgotPassword
          closeForgot={closeForgotPasswordModal}
          openLogin={openLoginModal}
        />
      </ReactModal>
    );
  });

  useEffect(() => {
    let _price = 0;
    if (loggedIn) {
      cartData?.map((item) => {
        if (item?.chapters?.length > 0) {
          item?.courses?.chapter.map((_item) => {
            item?.chapters?.map((_chap) => {
              if (_chap === _item?.id) {
                _price += _item?.price;
              }
            });
          });
        } else {
          if (item?.courses?.is_free === 0) {
            _price += item?.courses?.course_sale.on_sale
              ? item?.courses?.course_sale?.new_price
              : item?.courses?.course_sale?.old_price;
          }
        }
      });
      setCheckoutPrice(_price);
    } else {
      cartData?.map((item) => {
        if (item?.is_free === 0) {
          _price += item?.course_sale.on_sale
            ? item?.course_sale?.new_price
            : item?.course_sale?.old_price;
        }
      });
      setCheckoutPrice(_price);
    }
  }, [cartData]);

  const handleCheckoutBtn = () => {
    if (loggedIn) {
      history.push('/checkout')
    } else {
      openLoginModal();
      localStorage.setItem("update_cart", true)
    }
  }
  return (
    <>
      <header>
        <div
          className="logo top_order_1"
          onClick={() => {
            history.push('/')
          }}
          style={{ cursor: "pointer" }}
        >
          <a>
            <img src={icons.dawraty_logo} alt="" />
            {/* <span>Dawraty</span> */}
          </a>
        </div>
        <div className="top_order_3">
          <div className="top_category">
            {((user?.type === "student" && loggedIn) || !loggedIn) && (
              <div className="category_button">
                <figure>
                  <img src={icons.icon_category} alt="" />
                </figure>
                <span>
                  <a href="#" className="link_black">
                  {t('common.categories')}
                  </a>
                </span>
              </div>
            )}
            <div className="category_menu">
              <div className="menu_topic">
                <div className="menu_item">
                    <Link to={{ pathname: `/courses/all` }} className="menu_link">{t('routes.allCourses')}</Link>
                </div>
                {category?.map((cat) => (
                  <div className="menu_item" key={cat?.id}>
                    <Link
                      to={`/courses/${cat.name}`}
                      to={{
                        pathname: `/courses/${cat.name.split(" ").join("")}`,
                        state: cat.id,
                      }}
                      className="menu_link"
                    >
                      {t('dynamic', { en: cat.name, ar: cat?.translation?.value ?  cat?.translation?.value : cat?.name})}
                    </Link>
                    <div className="sub_menu">
                      <div className="sub_menu_topic">
                        {cat?.sub_categories?.map((sub_cat) => (
                          <div className="sub_menu_item" key={sub_cat?.id}>
                            <Link
                              // to={`/courses/${cat.name}/${sub_cat.name}`}
                              to={{
                                pathname: `/courses/${cat.name}/${sub_cat.name.split(" ").join("")}`,
                                state: cat.id,
                                sub_category: sub_cat.id,
                              }}
                              className="sub_menu_link"
                            >
                              {t('dynamic', { en: sub_cat.name, ar: sub_cat?.translation?.value ?  sub_cat?.translation?.value : sub_cat?.name})}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="search">
            <form>
              <div className="search_input" dir={ i18n.language == 'ar' ? "rtl" : "ltr"}>
                <div className="search_magnify">
                  <img src={icons.icon_search} alt="" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={searchParam}
                  placeholder={t('common.search')}
                  onChange={(e) => {
                    setSearchParam(e.target.value);
                    handleSearchChange(e, setSearchCourseData, loggedIn, countryInfo, setLoadingSearch);
                  }}
                  onFocus={() => {
                    setSearchBox(true);
                  }}
                  onBlur={() => {
                    (searchParam.length <= 0 ||
                      searchCourseData.length === 0) &&
                      setSearchBox(false);
                  }}
                />
                {searchParam && (
                  <span
                   className="reset"
                   onClick={() => {
                     setSearchBox(false);
                     setSearchParam("");
                     setSearchCourseData([]);
                   }}
                   style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            </form>
            {searchParam && (
            <div
              className="search_dropdown"
              style={{ display: searchBox ? "block" : "none" }}
              onBlur={() => setSearchBox(false)}
            >
              {loadingSearch ?
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <Spinner />
                </div>
                :
                searchCourseData === null || searchCourseData.length === 0 ? (
                  <div className="no_data plr_20">{i18n.language == 'ar' ? ar_messages.NO_RESULTS_FOUND : messages.NO_RESULTS_FOUND}</div>
                ) : (
                  searchCourseData?.map((item) => (
                    <div
                      className="search_row"
                      key={item?.id}
                      onClick={() => redirect(item?.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <figure>
                        <img
                          src={process.env.REACT_APP_ASSETS + item?.course_image}
                          alt=""
                        />
                      </figure>
                      <div className="search_row_details">
                        <h4>{item?.name}</h4>
                        <span>
                          {`${item?.instructor?.first_name} ${item?.instructor?.last_name}`}
                        </span>
                      </div>
                    </div>
                  ))
                )
              }
            </div>
             )}
          </div>
          <LanguageSelector />
          {!loggedIn && (
            <>
              <span
                // href="#"
                className="link_black"
                onClick={openInstructorSignupModal}
                style={{ cursor: "pointer" }}
              >
                {t('common.becomeAnInstructor')}
              </span>
            </>
          )}
        </div>

        <div className="top_order_2">
          {/* <!-- Cart Dropdown --> */}
          {((user?.type === "student" && loggedIn) || !loggedIn) && (
            <div className="top_cart">
              <div
                className="top_cart_wrapper"
                id="topCartBUtton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <figure>
                  <img src={icons.icon_cart} alt="" />
                </figure>
                {
                  cartCount > 0 ? cartCount > 9 ? (<div className="cart_count">9+</div>) : (<div className="cart_count">{cartCount}</div>) : ""
                }
              </div>
              <div
                className="dropdown-menu cart_dropdown"
                aria-labelledby="topCartBUtton"
                dir={ i18n.language == 'ar' ? "rtl" : "ltr"}
              >
                {cartData?.length === 0 || (_cartData === null && !loggedIn) ? (
                  <span className="p-5">{t('common.noCourseFound')}</span>
                ) : (
                  <>
                    <div className="dropdown_inner">
                      {
                      loggedIn
                        ? printcartData(cartData, helperProps, history, { free: t('common.free')})
                        : printCartDataLoggedOut(JSON.parse(localStorage.getItem("cartCourse")), helperProps, { free: t('common.free'), removeCourseFromCart: t('alertMessage.removeCourseFromCart')})
                      }
                    </div>
                    <div className="dropdown_checkout">
                      <strong>{(loggedIn ? cartData[0].courses.country_id === 112 : JSON.parse(localStorage.getItem("cartCourse"))[0].country_id === 112) ? t('course.total_price_in_kd' ,{ price: checkoutPrice}) : t('course.total_price_in_bd' ,{ price: checkoutPrice})}</strong>
                      {/* <Link to="/checkout"> */}
                        <button className="btn-block btn_primary big_btn full_btn" onClick={handleCheckoutBtn}>
                          {t('common.checkout')}
                        </button>
                      {/* </Link> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {loggedIn ? (
            <>
              <div className="top_notification">
                <div
                  className="notification_bell"
                  id="topNotifyButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={resetNotification}
                >
                  <figure>
                    <img src={icons.icon_notification} alt="" />
                  </figure>
                  { notificationCount > 0 ? notificationCount > 9 ? (<div className="notification_bubble">9+</div>) : (<div className="notification_bubble">{notificationCount}</div>) : null }
                </div>
                <div
                  className="dropdown-menu notify_dropdown"
                  aria-labelledby="topNotifyButton"
                  dir={ i18n.language == 'ar' ? "rtl" : "ltr"}
                >
                  <h3>{t('common.notifications')}</h3>
                  { notifications?.length > 0 ?  (
                    <>
                      { notifications?.map((item, index) => (
                      <Link to={{
                          pathname: item?.notification_type.name === 'doubt' ? '/chat/'+item.created_by : '',
                          state: { data: item.sender_details },
                        }}
                       className="notify_row"
                       key={index}
                      >
                          <figure>
                            <img src={icons.notification_1} alt="" />
                          </figure>
                          <div className="notify_content">
                            <p>
                              {t('notification.'+item.title,item.title)}
                            </p>
                            <span>{item.human_readable_created_at}</span>
                          </div>
                        </Link>
                      ))}
                    </>
                  )
                  :
                  (
                    <div className="py-3 no-data">{t('common.noNotifications')}</div>
                  )}
                  <Link to={{
                    pathname: "/notifications"
                  }}
                  className="btn btn-primary mt-3 button_primary">
                    {t('common.showAllNotifications')}
                  </Link>
                </div>
              </div>
              <div className="top_profile">
                <div
                  className="top_profile_img"
                  id="topProfileButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {user?.img_path !== null ? (
                    <img
                      src={process.env.REACT_APP_ASSETS + user?.img_path}
                      alt=""
                    />
                  ) : (
                    user?.first_name[0]
                  )}
                </div>
                <div
                  className="dropdown-menu profile_dropdown"
                  aria-labelledby="topProfileButton"
                  dir={ i18n.language == 'ar' ? "rtl" : "ltr"}
                >
                  <div className="pd_top">
                    <div className="top_profile_img">
                      {user?.img_path !== null ? (
                        <img
                          src={process.env.REACT_APP_ASSETS + user?.img_path}
                          alt=""
                        />
                      ) : (
                        `${user?.first_name[0]} ${user?.last_name[0]}`
                      )}
                    </div>
                    <div className="pd_ne">
                      <div className="pd_name">{`${user?.first_name} ${user?.last_name}`}</div>
                      <div className="pd_email">{user?.email}</div>
                    </div>
                  </div>
                  <div className="pd_bottom">
                    {user?.type === "student" && (
                      <Link to="/student-learning?courses">
                        <span>
                          <RiDashboardLine />
                        </span>
                        {t('common.myDashboard')}
                      </Link>
                    )}
                    <Link to="/profile">
                      <span>
                        <img src={icons.icon_user} alt="" />
                      </span>
                      {t('common.myProfile')}
                    </Link>
                    {user?.type === "student" && (
                      <Link to="addresses?all">
                        <span>
                          <img src={icons.icon_address} alt="" />
                        </span>
                        {t('common.myAddresses')}
                      </Link>
                    )}
                    {user?.type === "student" && (
                      <Link to="student-learning?wishlist">
                        <span>
                          <img src={icons.icon_heart} alt="" />
                        </span>
                        {t('common.wishlist')}
                      </Link>
                    )}
                    {user?.type === "student" && (
                      <Link to="/my-orders">
                        <span>
                          <img src={icons.icon_order_history} alt="" />
                          {/* <AiOutlineShopping /> */}
                        </span>
                        { t('common.ordersHistory')}
                      </Link>
                    )}
                    {user?.type === "instructor" && (
                      <Link to="/my-orders">
                        <span>
                          <img src={icons.icon_order_history} alt="" />
                          {/* <AiOutlineShopping /> */}
                        </span>
                        {t('common.recentOrders')}
                      </Link>
                    )}
                    {user?.type === "instructor" && (
                      <Link to="/my-courses">
                        <span>
                          <img src={icons.instructor_play} alt="" />
                          {/* <IoBookOutline /> */}
                        </span>
                        {t('common.myCourses')}
                      </Link>
                    )}
                    {user?.type === "instructor" && (
                      <Link to={"/my-students"}>
                        <span>
                          <img src={icons.icon_heart} alt="" />
                        </span>
                        {t('common.myStudents')}
                      </Link>
                    )}

                    <a href="/" onClick={logoutUser}>
                      <span>
                        <img src={icons.icon_logout} alt="" />
                      </span>
                      {t('common.logout')}
                    </a>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <span
              href="#"
              className="top_login btn_primary"
              onClick={openLoginModal}
              style={{ cursor: "pointer" }}
            >
              {t('common.login')}
            </span>
          )}
          {/* <div className="language_btn">Ar</div> */}
          {!loggedIn && (
            <a
              // href="#"
              className="hamburger"
              onClick={() => {
                setSidebar(true);
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </a>
          )}
        </div>
      </header>
      <div className="mobile_menu" style={{ right: sidebar && "0px" }}>
        <div
          className="mm_close"
          onClick={() => {
            setSidebar(false);
          }}
        >
          <img src={icons.icon_close_black} alt="" />
        </div>
        <div className="mm_inner">
          {/* <a href="#">Categories</a> */}
          <a href="#" onClick={openInstructorSignupModal}>
            {t('common.becomeAnInstructor')}
          </a>
        </div>
        <div className="mm_top_category">
          {((user?.type === "student" && loggedIn) || !loggedIn) && (
            <div className="mm_category_button" onClick={()=>{setShowMMCategory(!showMMCategory)}}>
              <figure>
                <img src={icons.icon_category} alt="" />
              </figure>
              <span>{t('common.categories')}</span>
            </div>
          )}
          {
            showMMCategory && (
              <div className="mm_category_menu">
                <div className="mm_menu_topic">
                  <div className="menu_item">
                      <Link to={{ pathname: `/courses/all` }} className="menu_link">{t('routes.allCourses')}</Link>
                  </div>
                  {category?.map((cat, index) => (
                    <MenuItem props={cat} key={index}></MenuItem>
                  ))}
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
}

export default Navbar;
